import React from "react";

import { Stack } from "@mui/material"

import { RankQualPvTvBox } from "./RankQualPvTvBox";


const RankQualPvTvBoxes = ({
  selectedRankDetails,
  totalOv,
  inDrawer,
  isError
}) => {
  const pv = selectedRankDetails?.metrics?.filter(item => item.name === "pv")[0];
  const tv = selectedRankDetails?.metrics?.filter(item => item.name === "tv")[0];
  const activeLegs = selectedRankDetails?.metrics?.filter(item => item.name === "active_legs")[0];

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap="12px"
      mb={inDrawer ? 1 : 3}
      ml={2.5}
      mr={2.5}
    >
      <Stack
        direction="row"
        gap={1}
        flexGrow={1}
        flexWrap={"wrap"}
      >
        {
          (pv || isError) &&
          <RankQualPvTvBox
            type="pv"
            inDrawer={inDrawer}
            values={pv}
            isError={isError}
          />
        }
        {
          (tv || isError) &&
          <RankQualPvTvBox
            type="tv"
            inDrawer={inDrawer}
            values={tv}
            isError={isError}
          />
        }
      </Stack>
      <Stack
        direction="row"
        gap={1}
        flexGrow={1}
        flexWrap={"wrap"}>
        {
          (activeLegs || isError) &&
          <RankQualPvTvBox
            type="active_legs"
            inDrawer={inDrawer}
            values={activeLegs}
            isError={isError}
          />
        }
        {
          (totalOv || isError) &&
          <RankQualPvTvBox
            type="total_ov"
            inDrawer={inDrawer}
            values={totalOv}
            isError={isError}
          />
        }
      </Stack>
    </Stack>
  )
}

export { RankQualPvTvBoxes }