import React, { useState, createContext, useEffect } from "react";
import { LANGUAGE_DETAILS, hasCountryCode } from "../common/languageDetails";

// use local translation table for development before pushing to rosetta
// import { localTranslationTable } from "./localTranslationTable";

const LanguageContext = createContext([{}, () => { }]);

const LanguageContextProvider = (props) => {
  //  get the language from local storage if it supported, otherwise default to english
  const cachedLanguage = localStorage.getItem('language');
  const cachedLanguageIsSupported = hasCountryCode(LANGUAGE_DETAILS, cachedLanguage);
  const [currentLanguage, setCurrentLanguage] = useState(cachedLanguageIsSupported ? cachedLanguage : "en-US");
  const currentLanguageCode = currentLanguage.split("-")[0];
  const [translationTable, setTranslationTable] = useState({});
  const [languageLoading, setLanguageLoading] = useState(true);
  // https://github.com/mui/material-ui/issues/22726 - material ui requires some icons to be flipped in code
  const [languageDir, setLanguageDir] = useState("ltr");

  const { REACT_APP_TRANSLATION_URL } = process.env;

  function localizeNumber(num) {
    const formatted = new Intl.NumberFormat(currentLanguageCode).format(num);
    return formatted
  }

  useEffect(() => {
    fetch(`${REACT_APP_TRANSLATION_URL}${currentLanguage}.json`)
      .then(data => data.json())
      .then(res => {
        setTranslationTable(res);
        setLanguageLoading(false);
        localStorage.setItem('language', currentLanguage);
      })
      .catch(err => {
        console.error(err);
        setLanguageLoading(false);
        localStorage.setItem('language', "en-US");
      })
    // eslint-disable-next-line
  }, [currentLanguage]);

  // set the global html direction based on the current language direction
  useEffect(() => {
    document.dir = languageDir;
  }, [languageDir])

  useEffect(() => {
    if (currentLanguage === "ar-EG") {
      setLanguageDir("rtl");
    } else {
      setLanguageDir("ltr");
    }
  }, [currentLanguage])

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        setCurrentLanguage,
        translationTable,
        setTranslationTable,
        languageLoading,
        localizeNumber,
        languageDir,
        setLanguageDir
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
