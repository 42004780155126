import React, { useState, forwardRef } from "react";
import { T } from "../hooks/useTranslate";
import { Tooltip, Stack, Typography, ClickAwayListener } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const GeneralAlert = ({ alertKey, tooltipKey, style }) => {

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = useState(false);
  const hasTooltip = !!tooltipKey

  const alertStyling = {
    margin: "0px 16px 0px 16px",
    alignItems: "center",
    padding: hasTooltip ? "8px 10px" : "10px",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.10)",
    background: "rgba(255, 195, 0, 0.05)"
  };

  const textStyling = {
    color: "#000",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "12px"
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event) => {
    setOpen(true);
  };

  const Alert = forwardRef((props, ref) => (
    <Stack
      {...props}
      direction="row"
      alignItems="center"
      gap={hasTooltip ? "8px" : "16px"}
      sx={{...alertStyling, ...style}}
      onClick={props.onClick}
      ref={ref}
    >
      <img src="icons/alert-icon.svg" alt="general-alert" />
      <Typography sx={textStyling} data-test-id="general-alert">
        <T>{alertKey}</T>
      </Typography>
    </Stack>
  ))

  // hoverable tooltip for wider screens
  if (viewportIsSmall) {
    return hasTooltip ? (
      <Tooltip
        arrow
        placement="bottom"
        PopperProps={tooltipPopperProps}
        slotProps={tooltipSlotProps}
        title={<TooltipText translationKey={tooltipKey} />}
      >
        <Alert />
      </Tooltip>
    ) : (
      <Alert />
    )
  }

  // clickable tooltip for mobile
  return hasTooltip ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          placement="bottom"
          PopperProps={tooltipPopperProps}
          slotProps={tooltipSlotProps}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<TooltipText translationKey={tooltipKey} />}
        >
          <Alert onClick={handleTooltipOpen} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <Alert />
  )
};

export { GeneralAlert };

const TooltipText = ({ translationKey }) => {
  return <Typography
    sx={{
      color: "#fff",
      fontSize: "9px",
      fontWeight: "400",
      width: "100px"
    }}>
    <T>{translationKey}</T>
  </Typography>
}

const tooltipSlotProps = {
  arrow: {
    sx: {
      color: "#566E81"
    }
  },
  tooltip: {
    sx: {
      backgroundColor: "#566E81",
      textAlign: "center"
    }
  }
}

const tooltipPopperProps = {
  disablePortal: true,
}