import React, { useContext } from "react";
import { T } from "../../../../hooks/useTranslate";

import { Stack, Typography, Skeleton } from "@mui/material";

import { DistributorContext } from "../../../../hooks/DistributorContext";
import { MemberContext } from "../../../../hooks/MemberContext";
import { SuccessTrackerSelectDropdown } from "./SuccessTrackerSelectDropdown";
import { RankQualSection } from "./RankQualSection";
import { CenturionSection } from "./CenturionSection";
import { GeneralAlert } from "../../../../components/GeneralAlert"

const SuccessTracker = () => {
  const { centurionHistory, historyApiInProgress, hasError } = useContext(DistributorContext);
  const { isDistributorLevelLimit } = useContext(MemberContext)
  const centurionDateCalculated = centurionHistory.centurionHistory?.items[0].value[0].metrics[0].dateCalculated;
  const currentCentCount = parseInt(centurionHistory.centurionHistory?.items[0].value[0].metrics[0].value);

  if (hasError) return (
    <Stack pt={2} pb={3} px={3} gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h2"><T>success_tracker</T></Typography>
        <SuccessTrackerSelectDropdown disabled={historyApiInProgress} />
      </Stack>
      <Stack alignItems="center" mb={1} mt={5}>
        <Typography variant="h3"><T>there_was_an_error</T>.</Typography>
      </Stack>
    </Stack>
  )

  return (
    <>
      {isDistributorLevelLimit &&
        <Stack pt={3}>
          <GeneralAlert alertKey="level_Limit_alert" tooltipKey="level_Limit_tooltip" />
        </Stack>
      }
      <Stack p={3} gap={2}>

        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Stack direction="row" gap={.75}>
            <Typography variant="h2"><T>success_tracker</T></Typography>
          </Stack>

          <SuccessTrackerSelectDropdown disabled={historyApiInProgress} />
        </Stack>
        {
          historyApiInProgress ?
            <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
              <Skeleton variant="rounded" width={350} height={300} />
              <Skeleton variant="rounded" width={350} height={100} />
            </Stack>
            :
            <Stack gap={2}>
              <RankQualSection />
              <CenturionSection currentCentCount={isNaN(currentCentCount) ? 0 : currentCentCount} centurionDateCalculated={centurionDateCalculated} />
            </Stack>
        }
      </Stack></>
  )
}

export { SuccessTracker }