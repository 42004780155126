import { useState, createContext, useEffect } from "react";

import { useApi } from "../hooks/useApi";

import { getMonthRange, getQualificationPeriodMonth } from "../common/date-functions";
import { getLowestNotQualifiedRankIdx, getLowestUnfilledRankIdx } from "../common/rank-qual-functions";
import { transformRanks } from "../common/format-functions";
import { countThirdLeg } from "../common/helper-functions";

const DistributorContext = createContext([{}, () => { }]);

const { DateTime } = require("luxon");

const DistributorContextProvider = (props) => {
  // distributor drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // distributorData
  const [distributorData, setDistributorData] = useState({});
  const [achievementsHistory, setAchievementsHistory] = useState({});
  const [centurionHistory, setCenturionHistory] = useState({});
  const [highestRankShort, setHighestRankShort] = useState("");

  const [totalOvDetails, setTotalOvDetails] = useState(null);

  // customer data for progress legs
  const [customer1, setCustomer1] = useState({});
  const [customer2, setCustomer2] = useState({});
  const [customer3, setCustomer3] = useState({});
  const [thirdLegCount, setThirdLegCount] = useState({});

  const [selectedRankDetails, setSelectedRankDetails] = useState({});

  // use for loading state
  const [historyApiInProgress, setHistoryApiInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);

  // boolean to persist rank across month changes
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [dateCalculated, setDateCalculated] = useState(null);

  // month dropdown
  const [contextMonthRange, setContextMonthRange] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  // rank dropdown
  const [rank, setRank] = useState("Mgr");
  const [rankOptions, setRankOptions] = useState([]);

  const { sendRequest: promiseSendRequest } = useApi();

  // get /achievementsHistory and centurionHistory each time there is a change to distributorData or selectedMonth
  useEffect(() => {
    setContextMonthRange(getMonthRange(6));
    setSelectedMonth(getQualificationPeriodMonth(6));
  }, [distributorData]);

  // begin promise chain when distributor changes or month changes
  useEffect(() => {
    const abortController = new AbortController();
    setHasError(false);
    setDateCalculated(null);
    // work with href data when received from MemberContext
    if (JSON.stringify(distributorData) !== "{}") {
      setHistoryApiInProgress(true);
      const distributorHrefSplit = distributorData.href.split("/");
      const distributorHref =
        distributorHrefSplit[distributorHrefSplit.length - 1];

      const promise1 = promiseSendRequest({
        method: "get",
        endpoint: `customers/${distributorHref}/achievementsHistory?expand=metrics&period=${selectedMonth}`,
        abortController,
      });

      const promise2 = promiseSendRequest({
        method: "get",
        endpoint: `customers/${distributorHref}/centurionHistory?period=${selectedMonth}`,
        abortController,
      });

      const promise3 = promiseSendRequest({
        method: "get",
        endpoint: `customers/${distributorHref}/metricsProfileHistory`,
        abortController,
      });

      Promise.all([promise1, promise2, promise3]).then((values) => {
        setAchievementsHistory(values[0].data);
        setCenturionHistory(values[1].data);
        setHistoryApiInProgress(false);
        setDateCalculated(values[0]?.data?.items[0]?.value[0]?.metrics[0]?.dateCalculated);

        const scopedRankOptions = values[0].data?.items[0].value.filter(rank => {
          return !["Asc", "DIA2"].includes(rank.achievement.rankShort) && rank.achievement.rankShort
        })

        setRankOptions(transformRanks(scopedRankOptions));

        const achievementsHistoryRes = values[0]?.data?.items[0].metricsDetails;

        // if user has legs, populate setCustomer state
        setCustomer1(achievementsHistoryRes.ov_leg1?.customers?.[0]?.customer ?? achievementsHistoryRes.ov_leg1?.customers?.[0]?.customer);
        setCustomer2(achievementsHistoryRes.ov_leg2?.customers?.[0]?.customer ?? achievementsHistoryRes.ov_leg2?.customers?.[0]?.customer);
        setCustomer3(achievementsHistoryRes.ov_leg3hc?.customers?.length ?? achievementsHistoryRes.ov_leg3hc?.customers?.length);

        // Count partners and members for third leg
        const thirdLegCount = countThirdLeg(achievementsHistoryRes.ov_leg3hc?.customers);
        setThirdLegCount(thirdLegCount);

        // set highest rank for upper left chip
        // if any of these values (from ranks.js) default to Distributor for displayed upper left chip
        const distributorRankCodes = [
          "As",
          "FP",
          "PC",
          "BA",
          "Ph1",
          "P1",
          "Asc",
          "Mbr",
          "JrM",
        ];
        const highestRankFromApi =
          values[2].data.aggregate?.cumulativeMetricsProfile?.highestRankShort;

        setHighestRankShort(
          distributorRankCodes.includes(highestRankFromApi)
            ? "Dst"
            : highestRankFromApi
        );

        // get default rank selection using these two indexes
        let lastMonthHighestRankIdx = 0;

        // build string for current and last month to filter /metricsHistory response
        const currentMonthSplit = selectedMonth
          ? DateTime.fromISO(selectedMonth).toISODate().split("-")
          : null;
        const currentMonthString = `${currentMonthSplit[0]}-${currentMonthSplit[1]}`;
        const lastMonthSplit = selectedMonth
          ? DateTime.fromISO(selectedMonth)
              .minus({ months: 1 })
              .toISODate()
              .split("-")
          : null;
        const twoMonthsAgoSplit = selectedMonth
          ? DateTime.fromISO(selectedMonth)
              .minus({ months: 2 })
              .toISODate()
              .split("-")
          : null;
        const lastMonthString = `${lastMonthSplit[0]}-${lastMonthSplit[1]}`;
        const twoMonthsAgoString = `${twoMonthsAgoSplit[0]}-${twoMonthsAgoSplit[1]}`;

        const totalOvValue = values[2]?.data?.items.filter(item => item.period === currentMonthString)[0].value?.ov;
        setTotalOvDetails({
          value: totalOvValue,
          requiredValue: null,
          qualified: null
        })

        // filter /metricsHistory response to get last month
        let lastMonthHighestRank = values[2]?.data?.items?.filter((item) => {
          return item.period === lastMonthString;
        });

        // // if highest rank last month is Dst, may issue with MENA or commissions engine, but use highest rank achieved two months ago for default rank
        if (lastMonthHighestRank[0]?.value.rankShort === "Dst") {
          lastMonthHighestRank = values[2]?.data?.items?.filter((item) => {
            return item.period === twoMonthsAgoString
          }) || [];
        }


        // iterate through ranks and set lastMonthHighestRankIdx based on the last month if possible
        if (lastMonthHighestRank.length) {
          scopedRankOptions.forEach((rankItem, idx) => {
            if (rankItem.achievement.rankShort === lastMonthHighestRank[0]?.value?.rankShort) {
              lastMonthHighestRankIdx = idx;
            }
          });
        }

        // apply default rank logic on initial load
        if (isInitialLoad) {
          const lowestNotQualifiedRankIdx = getLowestNotQualifiedRankIdx(scopedRankOptions);
          const lowestUnfilledRankIdx = getLowestUnfilledRankIdx(scopedRankOptions);

          // using last month highest rank idx, not qualified from API rank idx, and unfilled rank idx, select the default rank
          // outer if compares qualified from the API vs. last month highest
          // inner if then checks for filled ranks
          if (lowestNotQualifiedRankIdx >= lastMonthHighestRankIdx) {
            if (lowestUnfilledRankIdx > lowestNotQualifiedRankIdx) {
              setRank(scopedRankOptions[lowestUnfilledRankIdx].achievement.rankShort);
            } else {
              setRank(scopedRankOptions[lowestNotQualifiedRankIdx].achievement.rankShort);
            }
          } else {
            if (lowestUnfilledRankIdx > lastMonthHighestRankIdx) {
              setRank(scopedRankOptions[lowestUnfilledRankIdx].achievement.rankShort);
            } else {
              setRank(scopedRankOptions[lastMonthHighestRankIdx].achievement.rankShort);
            }
          }
        }

        setIsInitialLoad(false);
      }).catch(err => {
        console.log('err:', err);
        setHistoryApiInProgress(false);
        setHasError(true);
        resetContext();
      });
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorData, selectedMonth]);

  // when rank changes, get new values for progress bars
  useEffect(() => {
    // rank changes
    setSelectedRankDetails(rankOptions.filter(item => item.rank === rank)[0]);
  }, [rankOptions, rank]);

  const resetContext = () => {
    setAchievementsHistory({});
    setHasError(false);
    setHighestRankShort("");
    setSelectedRankDetails({});

    setCustomer1({});
    setCustomer2({});
    setCustomer3({});
    setThirdLegCount({});
    setTotalOvDetails(null);

    setRank("Mgr");
    setIsInitialLoad(true);
    setRankOptions([]);
    setDateCalculated(null);
  };

  return (
    <DistributorContext.Provider
      value={{
        contextMonthRange,
        setContextMonthRange,
        selectedMonth,
        setSelectedMonth,
        rank,
        setRank,
        rankOptions,
        setRankOptions,
        distributorData,
        setDistributorData,
        achievementsHistory,
        setAchievementsHistory,
        centurionHistory,
        setCenturionHistory,
        historyApiInProgress,
        setHistoryApiInProgress,
        customer1,
        customer2,
        customer3,
        highestRankShort,
        resetContext,
        hasError,
        selectedRankDetails,
        isDrawerOpen,
        setIsDrawerOpen,
        thirdLegCount,
        totalOvDetails,
        dateCalculated
      }}
    >
      {props.children}
    </DistributorContext.Provider>
  );
};

export { DistributorContext, DistributorContextProvider };